import React from "react";
import logo from "../../assets/logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import navbrextraimg from "../../assets/navbarextraimg.png";
const Navbar = () => {
  return (
    <div
      style={{
        padding: "10px",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <MenuIcon style={{ marginBottom: "12px" }} />
          <div>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "110px",
              }}
            />
          </div>
        </div>

        <div style={{ marginRight: "0px" }}>
        <ShoppingCartIcon style={{ marginBottom: "10px" }}/>
        </div>
      </div>
      <div>
        <input
          type="text"
          placeholder="Search for Products Brands and More"
          style={{
            width: "98%",
            backgroundColor: "#f1f5ff",
            border: "none",
            height: "40px",
            paddingLeft: "10px",
            borderRadius: "8px",
          }}
        />
      </div>
      <div>
        <img src={navbrextraimg} alt="navbrextraimg" style={{width:"100%",marginTop:"5px"}} />
      </div>
    </div>
  );
};

export default Navbar;
