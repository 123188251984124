import React, { useState } from 'react'
import addresslogo from "../../assets/address.png"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem, TextField,Select,InputLabel,FormControl } from '@mui/material';


const Address = () => {
  const {id}=useParams()
  const navigate = useNavigate();
  const [userAddress, setUserAddress] = useState({ country: "India" });

  const HandleChange = (e) => {
    const { name, value } = e.target;

    setUserAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleNumericInput = (e) => {
    const { name, value } = e.target;

    // Allow only numeric characters
    const numericValue = value.replace(/\D/g, "");

    // Limit the input length
    const maxLength = name === "mobile" ? 10 : 6;
    const truncatedValue = numericValue.slice(0, maxLength);

    setUserAddress((prev) => ({
      ...prev,
      [name]: truncatedValue,
    }));
  };

  const HandleSubmit = (e) => {
    e.preventDefault();
    localStorage.setItem("userAddress",JSON.stringify(userAddress))
    navigate(`/checkout/${id}`, { replace: true });
  };
  return (
    <div>
        <div style={{display:"flex",gap:"10px",alignItems:"center",padding:"10px"}}>
         <KeyboardBackspaceIcon
            onClick={() => navigate("/")}
          />
          <span>Add Delivery Address</span>
        </div>
        <img src={addresslogo} alt="address" style={{width:"100%"}} />
        <div>
        <form
          id="Adress"
          onSubmit={HandleSubmit}
          style={{
            padding: "10px",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}>
          <TextField
            id="outlined-basic"
            label="Full Name (Required)*"
            variant="outlined"
            size="medium"
            sx={{
              width: "100%",
            }}
            name="fullname"
            onChange={HandleChange}
          />
          <TextField
            id="outlined-basic"
            label="Mobile Number (Required)*"
            variant="outlined"
            size="medium"
            type="text"
            inputProps={{ maxLength: 10 }}
            sx={{
              width: "100%",
            }}
            value={userAddress?.mobile || ""}
            name="mobile"
            onChange={handleNumericInput}
          />
          <TextField
            id="outlined-basic"
            label="Pincode (Required)*"
            variant="outlined"
            size="medium"
            type="text"
            inputProps={{ maxLength: 6 }}
            sx={{
              width: "100%",
            }}
            value={userAddress?.pincode || ""}
            name="pincode"
            onChange={handleNumericInput}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}>
            <TextField
              id="outlined-basic"
              label="City (Required)*"
              variant="outlined"
              size="medium"
              sx={{
                width: "48%",
              }}
              name="city"
              onChange={HandleChange}
            />
            <FormControl
              size="medium"
              sx={{
                width: "48%",
              }}>
              <InputLabel>State (Required)*</InputLabel>
              <Select
                name="state"
                onChange={HandleChange}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="State (Required)*">
                <MenuItem value="">Select a state</MenuItem>
                <MenuItem value="Andaman and Nicobar Islands">
                  Andaman and Nicobar Islands
                </MenuItem>
                <MenuItem value="Andhra Pradesh">Andhra Pradesh</MenuItem>
                <MenuItem value="Arunachal Pradesh">Arunachal Pradesh</MenuItem>
                <MenuItem value="Assam">Assam</MenuItem>
                <MenuItem value="Bihar">Bihar</MenuItem>
                <MenuItem value="Chandigarh">Chandigarh</MenuItem>
                <MenuItem value="Chhattisgarh">Chhattisgarh</MenuItem>
                <MenuItem value="Dadra and Nagar Haveli">
                  Dadra and Nagar Haveli
                </MenuItem>
                <MenuItem value="Daman and Diu">Daman and Diu</MenuItem>
                <MenuItem value="Delhi">Delhi</MenuItem>
                <MenuItem value="Goa">Goa</MenuItem>
                <MenuItem value="Gujarat">Gujarat</MenuItem>
                <MenuItem value="Haryana">Haryana</MenuItem>
                <MenuItem value="Himachal Pradesh">Himachal Pradesh</MenuItem>
                <MenuItem value="Jammu and Kashmir">Jammu and Kashmir</MenuItem>
                <MenuItem value="Jharkhand">Jharkhand</MenuItem>
                <MenuItem value="Karnataka">Karnataka</MenuItem>
                <MenuItem value="Kerala">Kerala</MenuItem>
                <MenuItem value="Ladakh">Ladakh</MenuItem>
                <MenuItem value="Lakshadweep">Lakshadweep</MenuItem>
                <MenuItem value="Madhya Pradesh">Madhya Pradesh</MenuItem>
                <MenuItem value="Maharashtra">Maharashtra</MenuItem>
                <MenuItem value="Manipur">Manipur</MenuItem>
                <MenuItem value="Meghalaya">Meghalaya</MenuItem>
                <MenuItem value="Mizoram">Mizoram</MenuItem>
                <MenuItem value="Nagaland">Nagaland</MenuItem>
                <MenuItem value="Odisha">Odisha</MenuItem>
                <MenuItem value="Puducherry">Puducherry</MenuItem>
                <MenuItem value="Punjab">Punjab</MenuItem>
                <MenuItem value="Rajasthan">Rajasthan</MenuItem>
                <MenuItem value="Sikkim">Sikkim</MenuItem>
                <MenuItem value="Tamil Nadu">Tamil Nadu</MenuItem>
                <MenuItem value="Telangana">Telangana</MenuItem>
                <MenuItem value="Tripura">Tripura</MenuItem>
                <MenuItem value="Uttar Pradesh">Uttar Pradesh</MenuItem>
                <MenuItem value="Uttarakhand">Uttarakhand</MenuItem>
                <MenuItem value="West Bengal">West Bengal</MenuItem>
              </Select>
            </FormControl>
          </div>
          <TextField
            id="outlined-basic"
            label="House No., Building Name (Required)*"
            variant="outlined"
            size="medium"
            sx={{
              width: "100%",
            }}
            name="address"
            onChange={HandleChange}
          />
          <TextField
            id="outlined-basic"
            label="Road name, Area, Colony (optional)"
            variant="outlined"
            size="medium"
            sx={{
              width: "100%",
            }}
            name="landmark"
            onChange={HandleChange}
          />

          <button
            style={{
              backgroundColor: "#FA651B",
              color: "white",
              border: "none",
              padding: "20px",
              bottom: 0,
              left: 0,
              width: "100%",
              marginTop:"5px",
              borderRadius:"5px"
            }}>
            Save Address
          </button>
        </form>
        </div>
    </div>
  )
}

export default Address