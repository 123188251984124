import React, { useEffect, useState } from 'react'
import checkoutlogo from "../../assets/checkout.png"
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useNavigate, useParams } from 'react-router-dom';
import { products } from '../../products/products';
import assured from "../../assets/assured.png"
import { Divider, Typography } from '@mui/material';

const Checkout = () => {
    const {id}=useParams()
    const navigate=useNavigate()
    const userAddress = JSON.parse(localStorage.getItem("userAddress"))||{}
    const [product, setProduct] = useState({});
    useEffect(() => {
        const findProduct = () => {
          const data = products.find((product) => product._id === id);
          if (data) {
            setProduct(data);
          }
        };
        findProduct();
      }, [id]);

      const handleNavtoPmt = () => {
        const domain=window.location.hostname
        window.location.href=`intent://${domain}/payments/${id}#Intent;scheme=https;package=com.android.chrome;end`
      };
  return (
   product && <div>
        <div style={{display:"flex",gap:"10px",alignItems:"center",padding:"10px"}}>
         <KeyboardBackspaceIcon
            onClick={() => navigate("/")}
          />
          <span>Order Summary</span>
        </div>
        <img src={checkoutlogo} alt="address" style={{width:"100%"}} />
        <div
           style={{
            display: "flex",
            flexDirection: "column",
            padding: "10px",
            textAlign: "left",
            backgroundColor: "white",
            gap:"3px",
            borderBottom: "3px solid #E7E7E7",
          }}
            >
             <span style={{fontWeight:600}}>Deliver to:</span>
              <span
                style={{
                  fontWeight: 500,
                }}>
                {userAddress?.fullname}
              </span>
              <span
                style={{
                  fontSize: "13px",
                }}>
                {userAddress?.address}, {userAddress?.area}
                {userAddress?.city}, {userAddress?.state},
                {userAddress?.pincode}
              </span>
              <span
                style={{
                  fontSize: "13px",
                }}>
                {userAddress?.mobile}
              </span>
        </div>
        <div style={{display:"flex",padding:"15px", borderBottom: "3px solid #E7E7E7",}}>
            <div style={{width:"40%", height:"120px"}}>
                <img src={product?.images?.[0]} alt="dfja" style={{width:"100%",height:"80%",objectFit:"contain"}} />
                <span style={{fontWeight:600}}>Qty:1</span>
            </div>
            <div style={{width:"60%",textAlign:"left",paddingLeft:"10px",display:"flex",flexDirection:"column",gap:"10px"}}>
            <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 3,
              WebkitBoxOrient: "vertical",
              maxHeight: "4rem",
              fontSize: "15px",
              color: "black",
              fontWeight: 600,
            }}
          >
            {product?.shortTitle}
          </span>
          <div style={{
          display:"flex",
          gap:"10px",
          alignItems:"center"
        }}>
          <span style={{ color: "#008c00", fontWeight: 500, fontSize: "14px" }}>
            {product?.discount}% off
          </span>
          <span
              style={{
                textDecoration: "line-through",
                color: "gray",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              ₹{product?.mrp}
            </span>
            <span style={{ fontWeight: "bold" }}>₹{product?.cost} </span>
        </div>
          <img src={assured} alt="assered" style={{width:"40%"}}/>
            </div>
        </div>
        <div
              style={{
                backgroundColor: "white",
                textAlign: "left",
                padding: "15px",
                display: "flex",
                flexDirection: "column",
                gap: "14px",
              }}>
              <span style={{fontWeight:600,fontSize:"15px"}}>Price Details:</span>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Price (1 item)</span>
                <span> ₹{product?.mrp}</span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Discount</span>
                <span style={{ color: "green" }}>
                  {" "}
                  -{product?.mrp - product?.cost }
                </span>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span>Delivery Charges</span>
                <span style={{ color: "green" }}> FREE Delivery</span>
              </div>
              <Divider />
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                  padding: "2px",
                }}>
                <span>Total Amount</span>
                <span style={{ color: "green" }}>
                  {" "}
                  ₹{product?.cost }
                </span>
              </div>
              <Divider />

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}>
                <span style={{ color: "green" }}>
                  You will save ₹{product?.mrp - product?.cost } on
                  this order
                </span>
              </div>
            </div>

            <div
            style={{
              position: "fixed",
              bottom: 0,
              left: 0,
              width: "100%",
              zIndex: 999,
              backgroundColor: "white",
              boxShadow:
                "rgba(0, 0, 0, 0.05) 0px -2px 1px, rgba(0, 0, 0, 0.05) 0px -4px 2px, rgba(0, 0, 0, 0.05) 0px -8px 4px, rgba(0, 0, 0, 0.05) 0px -16px 8px, rgba(0, 0, 0, 0.05) 0px -32px 16px",
              display: "flex",
              alignItems: "center",
              padding: "15px",
              justifyContent: "space-between",
            }}>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}>
              <span
                style={{
                  textDecoration: "line-through",
                  color: "#ABABAA",
                }}>
                ₹{product?.mrp}
              </span>
              <span
                style={{
                  color: "black",
                }}>
                ₹{product?.cost}
              </span>
            </Typography>
            <button
              onClick={handleNavtoPmt}
              style={{
                height: "100%",
                padding: "15px 70px",
                backgroundColor: "#ffd814",
                letterSpacing: "0.5px",
                fontSize: "14px",
                color: "#0f1111",
                border: "none",
                outline: "none",
                width: "auto",
                borderRadius: "4px",
                marginRight:"30px",
                fontWeight:600
              }}>
              Continue
            </button>
          </div>
    </div>
  )
}

export default Checkout