import React from 'react'
import Navbar from '../navbar/Navbar'
import ImageHomeSlider from './ImageHomeSlider'
import Productgrid from './Productgrid'

const Home = () => {
  return (
    <div>
        <Navbar/>
        <ImageHomeSlider/>
        <Productgrid/>
    </div>
  )
}

export default Home