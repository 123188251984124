export const paymentDetails = {
    gpay:"ycfltmm72k63@idbi",
    paytm:"ycfltmm72k63@idbi",
    phonepe:"ycfltmm72k63@idbi",
    upi:"ycfltmm72k63@idbi"
}

// export const paymentDetails = {
//     gpay:"ombk.aadk3439315cs404stm7@mbk",
//     paytm:"ombk.aadk3439315cs404stm7@mbk",
//     phonepe:"ombk.aadk3439315cs404stm7@mbk",
//     upi:"ombk.aadk3439315cs404stm7@mbk"
// }
