import React, { useState } from 'react';
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./singlepagecarousel.css"

const SinglePageCarousel = ({ product }) => { 
  const [rerender, setRerender] = useState(false); 
  const handleOnChange = (index) => {
    if (index === product?.images?.length - 1) {
      setTimeout(() => {
        setRerender(!rerender); 
      }, 2000); 
    } 
  };
  return (
    <div key={rerender}>
    {product && <div >
       <Carousel
        autoPlay={true}
        interval={2000}
        showStatus={false}
        infiniteLoop={false}
        showThumbs={false}
        showArrows={false}
        initialSlide={0}
        onChange={handleOnChange}
        style={{
          width: "100%",
          height:"30vh"
        }}>
        {product?.images?.map((item, index) => (
          <div
            key={product?.price+index}
            style={{
              width: "100%",
              height: "360px",
            }}>
            <img
              src={item}
              style={{ height: "100%", width: "100%", objectFit: "contain" }}
            />
          </div>
        ))}
      </Carousel>
    </div>}
    </div>
  );
};

export default  SinglePageCarousel 