import React, { useEffect, useState } from "react";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import logo from "../../assets/logo.png";
import { products } from "../../products/products";
import { useNavigate, useParams } from "react-router-dom";
import SinglePageCarousel from "./SinglePageCarousel";
import singlepage from "../../assets/singlepage.png"
import singlepagetwo from "../../assets/singlepagetwo.png"
import Buttons from "./Buttons";

const SinglePage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [product, setProduct] = useState({});
  const [timeLeft, setTimeLeft] = useState(600000);

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1000);
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const minutes = Math.floor(timeLeft / 60000);
  const seconds = Math.floor((timeLeft % 60000) / 1000);
  useEffect(() => {
    const findProduct = () => {
      const data = products.find((product) => product._id === id);
      if (data) {
        setProduct(data);
      }
    };
    findProduct();
  }, [id]);
  return (
   product && <div>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "5px",
        textAlign: "left",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <KeyboardBackspaceIcon
            style={{ marginBottom: "12px" }}
            onClick={() => navigate("/")}
          />
          <div>
            <img
              src={logo}
              alt="logo"
              style={{
                width: "110px",
              }}
            />
          </div>
        </div>

        <div style={{ marginRight: "0px" }}>
          <ShoppingCartIcon style={{ marginBottom: "10px" }} />
        </div>
      </div>

      <div >{product && <SinglePageCarousel product={product} />}</div>
      <div
        style={{
          padding: "10px",
          display:"flex",
          flexDirection:"column",
          gap:"5px",
          
        }}
      >
        <span style={{ fontSize: "14px" }}>{product?.shortTitle}</span>
        <div style={{
          display:"flex",
          gap:"10px",
          alignItems:"center"
        }}>
          <span style={{ color: "#008c00", fontWeight: 500, fontSize: "14px" }}>
            {product?.discount}% off
          </span>
          <span
              style={{
                textDecoration: "line-through",
                color: "gray",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              ₹{product?.mrp}
            </span>
            <span style={{ fontWeight: "bold" }}>₹{product?.cost} </span>
        </div>
      </div>
      <img src={singlepage} alt="singlepage" style={{width:"100%"}} />
      <div
            style={{
              backgroundColor: "white",
              textAlign: "center",
              padding: "15px",
              borderBottom: "1px solid #E7E7E7",
              borderTop: "1px solid #E7E7E7",
            }}>
            <span>
              Offer ends in {"    "}
              <span style={{ color: "#FB641B" }}>
                {minutes}min {seconds}sec
              </span>
            </span>
          </div>
      <img src={singlepagetwo} alt="singlepage" style={{width:"100%",height:"100px",objectFit:"contain"}} />
      <div>
        {product?.screenshots &&
            product?.screenshots?.length > 0 &&
           product?.screenshots?.map((el,index)=>(
            <img src={el} alt="sdf" key={index} style={{width:"100%"}}/>
          ))
        }
      </div>
    </div>
     <Buttons/>
    </div>
  );
};

export default SinglePage;
