import React from "react";
import assured from "../../assets/assured.png";
import { products } from "../../products/products";
import { Link } from "react-router-dom";

const Productgrid = () => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(2,1fr)",
        textAlign: "left",
      }}
    >
      {products?.map((el, index) => (
        <Link to={el?._id} key={el?._id} style={{textDecoration:"none",color:"inherit"}}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "5px",
            padding: "15px",
            border: "1px solid #E7E7E7",
          }}
        >
          <img
            style={{ width: "100%", height: "200px",objectFit:"contain" }}
            src={el?.images[0]}
            alt="img"
          />
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              display: "-webkit-box",
              WebkitLineClamp: 1,
              WebkitBoxOrient: "vertical",
              maxHeight: "3.6rem",
              fontSize: "14px",
              color: "black",
              fontWeight: 600,
            }}
          >
            {el?.shortTitle}
          </span>
          <span
            style={{
              display: "flex",
              gap: "10px",
              alignItems: "center",
            }}
          >
            <span
              style={{ color: "#008c00", fontWeight: 500, fontSize: "14px" }}
            >
              {el?.discount}% off
            </span>
            <span
              style={{
                textDecoration: "line-through",
                color: "gray",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              ₹{el?.mrp}
            </span>
          </span>
          <span style={{ display: "flex", alignItems: "center", gap: "8px" }}>
            <span style={{ fontWeight: "bold" }}>₹{el?.cost} </span>
            <img
              style={{ width: "60px", marginTop: "4px" }}
              src={assured}
              alt="assured"
            />
          </span>
          <button
            style={{
              border: "none",
              padding: "10px",
              color: "white",
              fontWeight: 600,
              backgroundColor: "#fb641b",
              width: "100%",
              borderRadius: "5px",
            }}
          >
            Add to Cart
          </button>
          <span
            style={{
              fontSize: "12px",
              color: "#008c00",
              textAlign: "center",
              fontWeight: 600,
            }}
          >
            Free Delivery In Two Days
          </span>
        </div>
        </Link>
      ))}
    </div>
  );
};

export default Productgrid;
