import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Box } from "@mui/material";
import { sliderhomeimages } from "../../sliderhomeimages/images";

const ImageHomeSlider = () => {

  return (
    <Carousel
      autoPlay={true}
      interval={2000}
      showStatus={false}
      infiniteLoop={true}
      showThumbs={false}
      showArrows={false}
      style={{
        width: "100%",
        height: "30vh",
      }}>
      {sliderhomeimages?.map((item, index) => (
        <Box
          key={index}
          sx={{
            width: "100%",
            height: "100%",
          }}>
          <img
            src={item}
            alt='djakljk'
            style={{ height: "100%", width: "100%", objectFit: "cover" }}
          />
        </Box>
      ))}
    </Carousel>
  );
};

export default ImageHomeSlider;
