import React from "react";
import { useNavigate, useParams } from "react-router";
const Buttons = () => {
  const { id } = useParams("");
  const navigate = useNavigate();

  const HandleClick = () => {
    navigate(`/address/${id}`, { replace: true });
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 999,
        height: "55px",
      }}>
      <div
        className="cart_btn"
        style={{
          display: "flex",
          boxShadow:
            "rgba(0, 0, 0, 0.05) 0px -2px 1px, rgba(0, 0, 0, 0.05) 0px -4px 2px, rgba(0, 0, 0, 0.05) 0px -8px 4px, rgba(0, 0, 0, 0.05) 0px -16px 8px, rgba(0, 0, 0, 0.05) 0px -32px 16px",
        }}>
        <button
          className="cart_btn2"
          onClick={HandleClick}
          style={{
            width: "50%",
            height: "100%",
            backgroundColor: "whitesmoke",
            fontSize: "13px",
            fontWeight: 600,
            border:"none",
            padding:"20px"
          }}>
          Add to Cart
        </button>
        <button
          className="cart_btn1"
          onClick={HandleClick}
          style={{
            width: "50%",
            height: "100%",
            fontSize: "13px",
            fontWeight: 600,
            backgroundColor:"#f1cc13",
            border:"none",
            padding:"20px"
          }}>
          Buy Now
        </button>
      </div>
    </div>
  );
};

export default Buttons;
